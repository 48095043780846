export const dashboardPagesMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',
		subMenu: null,
	},
	credentials: {
		id: 'credentials',
		text: 'Credentials',
		path: '/credentials',
		icon: 'Credentials',
		subMenu: null,
	},
	// TODO: REMOVE.
	streamLinks: {
		id: 'stream-links',
		text: 'My Links',
		path: '/stream-links',
		icon: 'ViewStream',
		subMenu: null,
	},
	linkVisits: {
		id: 'link-visits',
		text: 'Link Visits',
		path: '/link-visits/:hash',
		icon: 'linkVisits',
		subMenu: null,
	},
	myLinks: {
		id: 'my-links',
		text: 'My Links',
		path: '/my-links',
		icon: 'ViewStream',
		subMenu: null,
	},
	profile: {
		id: 'profile',
		text: 'Profile',
		path: '/profile',
		icon: 'Profile',
		subMenu: null,
	},
	guides: {
		id: 'guides',
		text: 'Guides',
		path: '/guides',
		icon: 'Guides',
		subMenu: null,
	},
	faq: {
		id: 'faq',
		text: 'Faq',
		path: '/faq',
		icon: 'Faq',
		subMenu: null,
	},
	tmAppManual: {
		id: 'tmAppManual',
		text: 'tmAppManual',
		path: '/tm-app-manual',
		icon: 'tmAppManual',
		subMenu: null,
	},
};

export const adminPanelMenu = {
	home: {
		id: 'admin-panel',
		text: 'Admin Panel',
		path: '/admin-panel',
		icon: 'Admin',
		subMenu: null,
	},
	balances: {
		id: 'balances',
		text: 'Balances',
		path: '/admin-panel/balances',
		icon: 'Balances',
		subMenu: null,
	},
	workerStats: {
		id: 'WorkerStats',
		text: 'WorkerStats',
		path: '/admin-panel/worker-stats',
		icon: 'WorkerStats',
		subMenu: null,
	},
};

export const demoPagesMenu = {
	auth: {
		id: 'auth',
		text: 'Auth Pages',
		icon: 'Extension',
	},
	split: {
		id: 'split',
		text: 'Split Links',
		path: '/split',
		icon: 'Split',
		subMenu: null,
	},
	login: {
		id: 'login',
		text: 'Login',
		path: 'auth/login',
		icon: 'Login',
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: 'auth/sign-up',
		icon: 'PersonAdd',
	},
	resetPassword: {
		id: 'ResetPassword',
		text: 'Reset Password',
		path: 'auth/reset-password',
		icon: 'PersonAdd',
	},
	page404: {
		id: 'Page404',
		text: '404 Page',
		path: 'auth/404',
		icon: 'ReportGmailerrorred',
	},
};

export const pageLayoutTypesPagesMenu = {
	layoutTypes: {
		id: 'layoutTypes',
		text: 'Page Layout Types',
	},
	blank: {
		id: 'blank',
		text: 'Blank',
		path: 'page-layouts/blank',
		icon: 'check_box_outline_blank ',
	},
	pageLayout: {
		id: 'pageLayout',
		text: 'Page Layout',
		path: 'page-layouts',
		icon: 'BackupTable',
		subMenu: {
			headerAndSubheader: {
				id: 'headerAndSubheader',
				text: 'Header & Subheader',
				path: 'page-layouts/header-and-subheader',
				icon: 'ViewAgenda',
			},
			onlyHeader: {
				id: 'onlyHeader',
				text: 'Only Header',
				path: 'page-layouts/only-header',
				icon: 'ViewStream',
			},
			onlySubheader: {
				id: 'onlySubheader',
				text: 'Only Subheader',
				path: 'page-layouts/only-subheader',
				icon: 'ViewStream',
			},
			onlyContent: {
				id: 'onlyContent',
				text: 'Only Content',
				path: 'page-layouts/only-content',
				icon: 'WebAsset',
			},
		},
	},
	asideTypes: {
		id: 'asideTypes',
		text: 'Aside Types',
		path: 'aside-types',
		icon: 'Vertical Split',
		subMenu: {
			defaultAside: {
				id: 'defaultAside',
				text: 'Default Aside',
				path: 'aside-types/default-aside',
				icon: 'ViewQuilt',
			},
			minimizeAside: {
				id: 'minimizeAside',
				text: 'Minimize Aside',
				path: 'aside-types/minimize-aside',
				icon: 'View Compact',
			},
		},
	},
};
