import React, { FC, useCallback, useContext, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { Email } from '@mui/icons-material';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import AuthContext from '../../../contexts/authContext';
import Spinner from '../../../components/bootstrap/Spinner';
import Alert, { AlertHeading, AlertLink } from '../../../components/bootstrap/Alert';
import { login, resetPasswordComplete, resetPasswordSubmit, signup } from '../../../Api/auth';
import { Verified } from '../../../components/icon/material-icons';

interface ILoginHeaderProps {
	isNewUser?: boolean;
	error: string;
	success: string;
	isResetPassword?: boolean;
	isUpdatePassword?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = ({
	isNewUser,
	error,
	success,
	isResetPassword,
	isUpdatePassword,
}) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Create Account,</div>
				<div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
				{error && <Alert color='danger'>{error}</Alert>}
				{success && <Alert color='success'>{success}</Alert>}
			</>
		);
	}

	if (isResetPassword) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
				<div className='text-center h4 text-muted mb-5'>
					Enter your Username / Email to reset password!
				</div>
				{error && <Alert color='danger'>{error}</Alert>}
				{success && <Alert color='success'>{success}</Alert>}
			</>
		);
	}

	if (isUpdatePassword) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
				<div className='text-center h4 text-muted mb-5'>
					Please update your password below to complete password reset.
				</div>
				{error && <Alert color='danger'>{error}</Alert>}
				{success && <Alert color='success'>{success}</Alert>}
			</>
		);
	}

	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
			{error && <Alert color='danger'>{error}</Alert>}
			{success && <Alert color='success'>{success}</Alert>}
		</>
	);
};
LoginHeader.defaultProps = {
	isNewUser: false,
	isResetPassword: false,
	isUpdatePassword: false,
};

interface ILoginProps {
	isSignUp?: boolean;
	isUpdatePassword?: boolean;
}
const Login: FC<ILoginProps> = ({ isSignUp, isUpdatePassword }) => {
	const { setAccessToken } = useContext(AuthContext);

	const { darkModeStatus } = useDarkMode();
	const { search } = useLocation();
	const queryParams = new URLSearchParams(search);
	const referer = queryParams.get('_ref'); // Get the _ref parameter

	const [signInPassword, setSignInPassword] = useState<boolean>(false);
	const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);
	const [isResetPassword, setIsResetPassword] = useState<boolean>(false);

	const navigate = useNavigate();
	const handleAuthSuccess = useCallback(() => navigate('/'), [navigate]);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginUsername: '',
			loginPassword: '',
			passResetPassword: '',
		},
		validate: (values) => {
			const errors: { loginUsername?: string; loginPassword?: string } = {};

			if (!values.loginUsername) {
				errors.loginUsername = 'Required';
			}

			if (!values.loginPassword) {
				errors.loginPassword = 'Required';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: async (values) => {
			setIsLoading(true);
			setError('');
			setSuccess('');

			try {
				const loginResult = await login({
					username: values.loginUsername,
					password: values.loginPassword,
				});

				if (loginResult.success && values.loginUsername && setAccessToken) {
					setAccessToken(loginResult.accessToken);
					setTimeout(() => {
						handleAuthSuccess();
					}, 5);
				} else {
					setError(loginResult?.message || 'Unexpected error occured, please try again.');
				}
			} catch (e) {
				setError('Unexpected error occured, please try again.');
			} finally {
				setIsLoading(false);
			}
		},
	});

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>('');
	const [success, setSuccess] = useState<string>('');
	const handleContinue = () => {
		setIsLoading(true);
		setSignInPassword(true);
		setIsLoading(false);
	};

	const [signupInfo, setSignupInfo] = useState({
		email: '',
		username: '',
		password: '',
		confirmPassword: '',
	});
	const handleSignup = async () => {
		setIsLoading(true);
		setError('');
		setSuccess('');

		try {
			const signupResult = await signup({
				email: signupInfo.email,
				password: signupInfo.password,
				confirmPassword: signupInfo.confirmPassword,
				username: signupInfo.username,
				referer,
			});

			if (signupResult.success && setAccessToken) {
				setSuccess('Signup Successfull, please login.');
			} else {
				setError(signupResult?.message || 'Unexpected error occured, please try again.');
			}
		} catch (e) {
			setError('Unexpected error occured, please try again.');
		} finally {
			setIsLoading(false);
		}
	};

	const handleResetPassword = async () => {
		setIsLoading(true);
		setError('');
		setSuccess('');

		try {
			const resetPasswordResult = await resetPasswordSubmit({
				email: formik.values.loginUsername,
			});

			if (resetPasswordResult.success) {
				setSuccess('Please check your email.');
			} else {
				setError(
					resetPasswordResult?.message || 'Unexpected error occured, please try again.',
				);
			}
		} catch (e) {
			setError('Unexpected error occured, please try again.');
		} finally {
			setIsLoading(false);
		}
	};

	const handleUpdatePassword = async () => {
		setIsLoading(true);
		setError('');
		setSuccess('');

		try {
			const resetPasswordResult = await resetPasswordComplete({
				password: formik.values.passResetPassword,
				token: new URL(window.location.href).searchParams.get('token'),
			});

			if (resetPasswordResult?.success) {
				setSuccess('Password updated successfully. Please login.');
			} else {
				setError(
					resetPasswordResult?.message || 'Unexpected error occured, please try again.',
				);
			}
		} catch (e) {
			console.log(e);
			setError('Unexpected error occured, please try again.');
		} finally {
			setIsLoading(false);
		}
	};

	function getPageTitle(
		_isUpdatePassword: any,
		_isResetPassword: boolean,
		_singUpStatus: boolean,
	): string {
		if (_isUpdatePassword) {
			return 'Update Password';
		}
		if (_isResetPassword) {
			return 'Reset Password';
		}
		return _singUpStatus ? 'Sign Up' : 'Login';
	}

	return (
		<PageWrapper
			isProtected={false}
			title={getPageTitle(isUpdatePassword, isResetPassword, singUpStatus)}
			className={classNames({ 'bg-dark': !singUpStatus, 'bg-light': singUpStatus })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						{/* <Alert color='secondary'>
							<AlertHeading>
								<Verified /> New here ?
							</AlertHeading>
							<span style={{ fontSize: 16 }}>
								Looking to try before you buy? 10 credits on us! Sign up and Just
								message us via telegram with your username
							</span>
						</Alert> */}
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5' />
								<div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-dark': darkModeStatus,
									})}>
									<div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													navigate('/auth/login');
													setSignInPassword(false);
													setSingUpStatus(false);
													setIsResetPassword(false);
													setError('');
													setSuccess('');
												}}>
												Login
											</Button>
										</div>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={!singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													navigate('/auth/login');
													setSignInPassword(false);
													setSingUpStatus(true);
													setIsResetPassword(false);
													setError('');
													setSuccess('');
												}}>
												Sign Up
											</Button>
										</div>
									</div>
								</div>

								<LoginHeader
									success={success}
									error={error}
									isNewUser={singUpStatus}
									isResetPassword={isResetPassword}
									isUpdatePassword={isUpdatePassword}
								/>

								{!isUpdatePassword ? (
									<form className='row g-4'>
										{singUpStatus ? (
											<>
												<div className='col-12'>
													<FormGroup
														id='signup-username'
														isFloating
														label='Username'>
														<Input
															type='text'
															autoComplete='off'
															value={signupInfo.username}
															onChange={(e: any) =>
																setSignupInfo({
																	...signupInfo,
																	username: e.target.value,
																})
															}
														/>
													</FormGroup>
												</div>
												<div className='col-12'>
													<FormGroup
														id='signup-email'
														isFloating
														label='Email'>
														<Input
															type='text'
															autoComplete='off'
															value={signupInfo.email}
															onChange={(e: any) =>
																setSignupInfo({
																	...signupInfo,
																	email: e.target.value,
																})
															}
														/>
													</FormGroup>
												</div>
												<div className='col-12'>
													<FormGroup
														id='signup-password'
														isFloating
														label='Password'>
														<Input
															type='password'
															autoComplete='off'
															value={signupInfo.password}
															onChange={(e: any) =>
																setSignupInfo({
																	...signupInfo,
																	password: e.target.value,
																})
															}
														/>
													</FormGroup>
												</div>
												<div className='col-12'>
													<FormGroup
														id='signup-confirm-password'
														isFloating
														label='Confirm password'>
														<Input
															type='password'
															autoComplete='off'
															value={signupInfo.confirmPassword}
															onChange={(e: any) =>
																setSignupInfo({
																	...signupInfo,
																	confirmPassword: e.target.value,
																})
															}
														/>
													</FormGroup>
												</div>
												<div className='col-12'>
													<Button
														color='info'
														className='w-100 py-3'
														onClick={handleSignup}
														isDisable={isLoading}>
														{isLoading && (
															<Spinner isSmall inButton isGrow />
														)}
														Sign Up
													</Button>
												</div>
											</>
										) : (
											<>
												<div className='col-12'>
													<FormGroup
														id='loginUsername'
														isFloating
														label='Username/Email'
														className={classNames({
															'd-none': signInPassword,
														})}>
														<Input
															autoComplete='off'
															value={formik.values.loginUsername}
															isTouched={formik.touched.loginUsername}
															invalidFeedback={
																formik.errors.loginUsername
															}
															isValid={formik.isValid}
															onChange={formik.handleChange}
															onBlur={formik.handleBlur}
															onFocus={() => {
																formik.setErrors({});
															}}
														/>
													</FormGroup>
													{signInPassword && (
														<div className='text-center h4 mb-3 fw-bold'>
															Hi, {formik.values.loginUsername}.
														</div>
													)}
													<FormGroup
														id='loginPassword'
														isFloating
														label='Password'
														className={classNames({
															'd-none': !signInPassword,
														})}>
														<Input
															type='password'
															autoComplete='current-password'
															value={formik.values.loginPassword}
															isTouched={formik.touched.loginPassword}
															invalidFeedback={
																formik.errors.loginPassword
															}
															validFeedback='Looks good!'
															isValid={formik.isValid}
															onChange={formik.handleChange}
															onBlur={formik.handleBlur}
														/>
													</FormGroup>
												</div>
												<div className='col-12'>
													{!isResetPassword && (
														<div className='text-right my-4 fw-bold'>
															<Link
																to='#'
																onClick={() => {
																	setSignInPassword(false);
																	setIsResetPassword(true);
																}}>
																Reset password
															</Link>
														</div>
													)}

													{(() => {
														if (!isResetPassword) {
															if (!signInPassword) {
																return (
																	<Button
																		color='warning'
																		className='w-100 py-3'
																		isDisable={
																			!formik.values
																				.loginUsername
																		}
																		onClick={handleContinue}>
																		{isLoading && (
																			<Spinner
																				isSmall
																				inButton
																				isGrow
																			/>
																		)}
																		Continue
																	</Button>
																);
															}
															return (
																<Button
																	color='warning'
																	className='w-100 py-3'
																	isDisable={isLoading}
																	onClick={formik.handleSubmit}>
																	{isLoading && (
																		<Spinner
																			isSmall
																			inButton
																			isGrow
																		/>
																	)}
																	Login
																</Button>
															);
														}
														return (
															<Button
																color='warning'
																className='w-100 py-3'
																isDisable={isLoading}
																onClick={handleResetPassword}>
																{isLoading && (
																	<Spinner
																		isSmall
																		inButton
																		isGrow
																	/>
																)}
																Reset Password
															</Button>
														);
													})()}
												</div>
											</>
										)}
									</form>
								) : (
									<form className='row g-4'>
										<div className='col-12'>
											<FormGroup
												id='passResetPassword'
												isFloating
												label='Password'>
												<Input
													type='password'
													autoComplete='current-password'
													value={formik.values.passResetPassword}
													isTouched={formik.touched.passResetPassword}
													invalidFeedback={
														formik.errors.passResetPassword
													}
													validFeedback='Looks good!'
													isValid={formik.isValid}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
												/>
											</FormGroup>
										</div>
										<div className='col-12'>
											<Button
												color='warning'
												className='w-100 py-3'
												isDisable={isLoading}
												onClick={() => handleUpdatePassword()}>
												{isLoading && <Spinner isSmall inButton isGrow />}
												Update Password
											</Button>
										</div>
									</form>
								)}
							</CardBody>
						</Card>
						<Card style={{ background: '#3390EC' }}>
							<div className='text-center' style={{ padding: '1rem' }}>
								<a
									target='__blank'
									href='http://t.me/securemypass_support2'
									style={{ color: 'white' }}>
									<span style={{ marginRight: 5, fontSize: 16 }}>Live Chat</span>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='28'
										height='28'
										fill='currentColor'
										viewBox='0 0 16 16'>
										<path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09' />
									</svg>
								</a>
							</div>
						</Card>
						<Card style={{ background: 'orangered' }}>
							<div className='text-center' style={{ padding: '1rem' }}>
								<a
									target='__blank'
									href='maito:support@securemypass.com'
									style={{ color: 'white' }}>
									<span style={{ marginRight: 5, fontSize: 16 }}>Email</span>
									<Email />
								</a>
							</div>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
	isUpdatePassword: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
	isUpdatePassword: false,
};

export default Login;
