import { backendUrl } from './config';

export async function login({ username, password }) {
	const response = await fetch(`${backendUrl}/auth/login`, {
		method: 'POST',
		headers: {
			'content-type': 'Application/JSON',
		},
		body: JSON.stringify({
			username,
			password,
		}),
	});

	const responseBody = await response.json();

	return responseBody;
}

export async function signup({ email, username, password, confirmPassword, referer }) {
	const response = await fetch(`${backendUrl}/auth/signup`, {
		method: 'POST',
		headers: {
			'content-type': 'Application/JSON',
		},
		body: JSON.stringify({
			email,
			username,
			password,
			confirmPassword,
			referer,
		}),
	});

	const responseBody = await response.json();

	return responseBody;
}

export async function resetPasswordSubmit({ email }) {
	const response = await fetch(`${backendUrl}/auth/reset-password/submit`, {
		method: 'POST',
		headers: {
			'content-type': 'Application/JSON',
		},
		body: JSON.stringify({
			email,
		}),
	});

	const responseBody = await response.json();

	return responseBody;
}

export async function resetPasswordComplete({ password, token }) {
	const response = await fetch(`${backendUrl}/auth/reset-password/`, {
		method: 'POST',
		headers: {
			'content-type': 'Application/JSON',
		},
		body: JSON.stringify({
			password,
			token,
		}),
	});

	const responseBody = await response.json();

	return responseBody;
}
